export default () => {

  const authStore = useAuthStore();

  function can(permissionName) {

    // is owner

    if (authStore.isAdmin) {
      return true
    }

    if (hasPermission(permissionName)) {
      return true
    }

    return false
  }

  function hasPermission(permissionName) {

    if (!authStore.userPermissions) {
      return false
    }

    let permission = authStore.userPermissions.permissions.find((permission) => permission.name == permissionName);

    if (!permission) {
      return false
    }

    return permission.has_permission
  }

  function isAdmin() {
    return authStore.isAdmin
  }

  function isSystemAdmin(role = null) {
    const { isSystemAdmin } = authStore;

    if (!role) return isSystemAdmin !== null;

    if (role === 'editor') {
      return isSystemAdmin === 'editor' || isSystemAdmin === 'owner';
    }

    if (role === 'owner') {
      return isSystemAdmin === 'owner';
    }

    return false;
  }

  function user() {
    return authStore.user
  }

  function salesman() {
    return authStore.user?.salesmen[0] ?? null
  }


  return {
    can,
    isAdmin,
    isSystemAdmin,
    user,
    salesman
  };
}